/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable prettier/prettier */
import React from 'react';
import PropTypes from 'prop-types';
import { Button, Container } from 'reactstrap';
import styled from 'styled-components';
import Layout from '../../components/Layout';
import SEO from '../../components/Seo';
import Jumbotron from '../../components/Jumbotron';
import { SECONDARY } from '../../utils/colors';
import Thumb from '../../assets/svgs/window.svg';
import CopyButton from '../../components/CopyButton';

const StyledTitle = styled.h2`
  font-size: 1.25rem;
`;

const Desiner = ({ location }) => (
  <Layout theme="blue" location={location}>
    <SEO
      title="CENTRAL DO FRETE - CNPJ 22.531.311/0001-10 - Razão Social CENTRAL DO FRETE INTERMEDIACAO E TECNOLOGIA DE LOGISTICA LTDA"
      description="Consulte o CNPJ da Central do Frete e seus dados cadastrais e endereços"
    />
    <Jumbotron
      Image={Thumb}
      background={SECONDARY}
      title="CENTRAL DO FRETE - CNPJ 22.531.311/0001-10"
      subtitle="Razão Social: CENTRAL DO FRETE INTERMEDIACAO E TECNOLOGIA DE LOGISTICA LTDA"
    />
    <Container className="pb-5 pt-4">
      <StyledTitle>Nome fantasia</StyledTitle>
      <p>CENTRALDOFRETE.COM <CopyButton text="CENTRALDOFRETE.COM" /></p>

      <StyledTitle>Razão social</StyledTitle>
      <p>CENTRAL DO FRETE INTERMEDIACAO E TECNOLOGIA DE LOGISTICA LTDA <CopyButton text="CENTRAL DO FRETE INTERMEDIACAO E TECNOLOGIA DE LOGISTICA LTDA" /></p>

      <StyledTitle>CNPJ da Central do Frete</StyledTitle>
      <p>22.531.311/0001-10 <CopyButton text="22.531.311/0001-10" /></p>

      <StyledTitle>Endereço</StyledTitle>
      <p>
        Rua São Paulo, 2333, sala 2 - Cerâmica <CopyButton text="Rua São Paulo, 2333, sala 2 - Cerâmica" />
        <br />
        São Caetano do Sul/SP <CopyButton text="São Caetano do Sul/SP" />
        <br />
        CEP: 09.530-211 <CopyButton text="09.530-211" />
      </p>

      <StyledTitle>Atividade econômica principal</StyledTitle>
      <p>74.90-1-04 - Atividades de intermediação e agenciamento de serviços e negócios em geral, exceto imobiliários <CopyButton text="74.90-1-04 - Atividades de intermediação e agenciamento de serviços e negócios em geral, exceto imobiliários" /></p>

      <StyledTitle>E-mail</StyledTitle>
      <p>falecom@centraldofrete.com <CopyButton text="falecom@centraldofrete.com" /></p>
      <br />
      <footer className="pb-5">
        <Button size="lg" href="/">
          Realizar Cotação Online
        </Button>
      </footer>
    </Container>
  </Layout>
);

Desiner.propTypes = {
  location: PropTypes.shape({
    search: PropTypes.string,
  }).isRequired,
};

export default Desiner;
